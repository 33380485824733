<template>
  <admin>
    <metatag title="Edit Infobip Account"></metatag>
    <page-header :back="{ to: { name: 'infobip-account.index' }, text: trans('Infobip Accounts') }">
      <template v-slot:title><span v-text="trans('Edit Infobip Account')"></span></template>
    </page-header>

    <div class="col-lg-9 col-12">
      <DetailsForm :details="details" />
    </div>
  </admin>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DetailsForm from "./partials/forms/Details.vue";

export default {
  components: {
    DetailsForm,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      details: "infobipAccount/GET_INFOBIP_ACCOUNT",
    }),
  },
  methods: {
    ...mapActions({
      getAccount: "infobipAccount/getInfoBipAccount",
    }),
  },
  async mounted() {
    await this.getAccount(this.$route.params.id);
  },
};
</script>
